import React, { useEffect, useState } from 'react';
import Joyride, { Step, CallBackProps } from 'react-joyride';
import { Beacon } from './modules/Beacon/Beacon';
import { Tooltip } from './modules/Tooltip/Tooltip';
import { Modal } from '@/Components/Modal/Modal';
import styles from './Tour.scss';

export type TourStep = {
  isModal?: boolean;
  callback?: () => void;
  link?: string;
} & Step;

type Props = {
  steps: TourStep[];
  runTour: boolean;
  activeStep: number;
  toggleTour: (val: boolean) => void;
  setTourStep: (val: number) => void;
  continuous?: boolean;
  scrollToFirstStep?: boolean;
};

export function Tour({
  steps,
  runTour,
  activeStep,
  setTourStep,
  toggleTour,
  continuous = true,
  scrollToFirstStep = true,
}: Props) {
  const [tourKey, setTourKey] = useState(0);
  const [stepIndex, setStepIndex] = useState(activeStep);
  const [changeType, setChangeType] = useState<'custom' | 'normal'>('normal');

  useEffect(() => {
    if (activeStep != stepIndex) {
      setStepIndex(activeStep);
      toggleTour(true);
      setChangeType('custom');
    }
  }, [activeStep]);

  const [modalData, setModalData] = useState<{
    visible: boolean;
    data?: TourStep;
  }>({ visible: false });

  const handleCallback = ({ type, index, action }: CallBackProps) => {
    if (type == 'tour:start') {
      if (steps[0]?.callback) {
        toggleTour(false);
        steps[0]?.callback?.();
        setTimeout(() => toggleTour(true), 500);
      }
    }

    if (type == 'tour:end') {
      setTourStep(0);
      setStepIndex(0);
      setTourKey(tourKey + 1);
      toggleTour(false);
    }

    if (type == 'tooltip' && steps[index].isModal) {
      toggleTour(false);
      setModalData({ visible: true, data: steps[index] });
    }

    if (type == 'step:after') {
      toggleTour(false);
      const nextNode = document.querySelector(steps[index + 1]?.target as string);

      if (!nextNode && action != 'prev' && index != steps.length - 1) {
        setStepIndex(0);
        setTourStep(0);
        setTourKey(tourKey + 1);
        return;
      }

      if (changeType == 'normal') {
        if (action == 'prev') {
          steps[index - 1]?.callback?.();
          setStepIndex(index - 1);
          setTourStep(index - 1);

          setTimeout(() => toggleTour(true), 500);
        } else {
          steps[index + 1]?.callback?.();
          setStepIndex(index + 1);
          setTourStep(index + 1);

          if (index != steps.length - 1) {
            setTimeout(() => toggleTour(true), 500);
          }
        }
      } else {
        setTimeout(() => toggleTour(true), 500);
        setChangeType('normal');
      }
    }
  };

  return (
    <>
      <Joyride
        key={tourKey}
        // disableOverlayClose={true}
        callback={handleCallback}
        continuous={continuous}
        run={runTour}
        scrollToFirstStep={scrollToFirstStep}
        steps={steps}
        stepIndex={stepIndex}
        beaconComponent={Beacon}
        tooltipComponent={Tooltip}
        styles={{
          options: {
            zIndex: 1000,
            arrowColor: getComputedStyle(document.body).getPropertyValue('--block-light-bg-color'),
          },
        }}
      />
      <Modal
        className={styles.modal}
        isOpen={modalData.visible}
        onClose={() => {
          setStepIndex(stepIndex + 1);
          setModalData({ visible: false });
          toggleTour(true);
        }}
      >
        <h2>{modalData.data?.title}</h2>
        {modalData.data?.content}
      </Modal>
    </>
  );
}
