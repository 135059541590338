import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TourState = {
  run: boolean;
  tour: string;
  activeStep: number;
};

const initialState: TourState = {
  run: false,
  tour: 'defaultTour',
  activeStep: 0,
};

const tourSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setRun(state, action: PayloadAction<{ run: boolean; tour?: string }>) {
      state.tour = action.payload.tour ?? 'defaultTour';
      state.run = action.payload.run;
    },
    setActiveStep(state, action: PayloadAction<number>) {
      state.activeStep = action.payload;
    },
  },
});

export const tourReducer = tourSlice.reducer;
export const tourActions = tourSlice.actions;
