import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useUploadTemplatesSettings() {
  const {
    tours: {
      OnlineArchiveSettings: { UploadTemplates, UploadTemplatesModal },
    },
  } = useLocalization();

  const defaultTour: TourStep[] = [
    {
      content: <div>{UploadTemplates.Step1.Text}</div>,
      placement: 'auto',
      target: '#uploadTemplates',
      title: UploadTemplates.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{UploadTemplates.Step2.Text}</div>,
      placement: 'auto',
      target: '#templatesTable',
      title: UploadTemplates.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{UploadTemplates.Step3.Text}</div>,
      placement: 'auto',
      target: '#crudExtended',
      title: UploadTemplates.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{UploadTemplates.Step4.Text}</div>,
      placement: 'auto',
      target: '.dx-toolbar-after',
      title: UploadTemplates.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      spotlightPadding: 0,
      styles: {
        spotlight: {
          maxWidth: '500px',
        },
      },
    },
    {
      content: <div>{UploadTemplates.Step5.Text}</div>,
      placement: 'auto',
      target: '.dx-dropdownmenu-button',
      title: UploadTemplates.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{UploadTemplates.Step6.Text}</div>,
      placement: 'auto',
      target: '#templateInfo',
      title: UploadTemplates.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{UploadTemplates.Step7.Text}</div>,
      placement: 'auto',
      target: '#generalInfo',
      title: UploadTemplates.Step7.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{UploadTemplates.Step8.Text}</div>,
      placement: 'auto',
      target: '#accountChartInfo',
      title: UploadTemplates.Step8.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{UploadTemplates.Step9.Text}</div>,
      placement: 'auto',
      target: '#intraCommunityInfo',
      title: UploadTemplates.Step9.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  const templateSettingsTour: TourStep[] = [
    {
      content: <div>{UploadTemplatesModal.Step1.Text}</div>,
      placement: 'auto',
      target: '#modalTitle',
      title: UploadTemplatesModal.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{UploadTemplatesModal.Step2.Text}</div>,
      placement: 'auto',
      target: '#isForAutoSave',
      title: UploadTemplatesModal.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{UploadTemplatesModal.Step3.Text}</div>,
      placement: 'auto',
      target: '#templateMainForm',
      title: UploadTemplatesModal.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{UploadTemplatesModal.Step4.Text}</div>,
      placement: 'auto',
      target: '#introCommunityForm',
      title: UploadTemplatesModal.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{UploadTemplatesModal.Step5.Text}</div>,
      placement: 'auto',
      target: '#standardAccountChart',
      title: UploadTemplatesModal.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      styles: {
        spotlight: {
          marginTop: '-25px',
        },
      },
    },
    {
      content: <div>{UploadTemplatesModal.Step6.Text}</div>,
      placement: 'auto',
      target: '#addNewScenario',
      title: UploadTemplatesModal.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{UploadTemplatesModal.Step7.Text}</div>,
      placement: 'auto',
      target: '#addScenarioForOtherCountries',
      title: UploadTemplatesModal.Step7.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{UploadTemplatesModal.Step8.Text}</div>,
      placement: 'auto',
      target: '#accountChartCrud',
      title: UploadTemplatesModal.Step8.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{UploadTemplatesModal.Step9.Text}</div>,
      placement: 'auto',
      target: '#modalNavButtons',
      title: UploadTemplatesModal.Step9.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  return {
    defaultTour,
    templateSettingsTour,
  };
}
