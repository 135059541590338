import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useAccountChartSettings() {
  const {
    tours: {
      OnlineArchiveSettings: { AccountChart, AccountChartModal },
    },
  } = useLocalization();

  const defaultTour: TourStep[] = [
    {
      content: <div>{AccountChart.Step1.Text}</div>,
      placement: 'auto',
      target: '#accountChart',
      title: AccountChart.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{AccountChart.Step2.Text}</div>,
      placement: 'top',
      target: '#accountChartsTable',
      title: AccountChart.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{AccountChart.Step3.Text}</div>,
      placement: 'auto',
      target: '#crudExtended',
      title: AccountChart.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{AccountChart.Step4.Text}</div>,
      placement: 'auto',
      target: '.dx-toolbar-after',
      title: AccountChart.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      spotlightPadding: 0,
      styles: {
        spotlight: {
          maxWidth: '500px',
        },
      },
    },
    {
      content: <div>{AccountChart.Step5.Text}</div>,
      placement: 'auto',
      target: '.dx-dropdownmenu-button',
      title: AccountChart.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  const accountChartSettingsTour: TourStep[] = [
    {
      content: <div>{AccountChartModal.Step1.Text}</div>,
      placement: 'auto',
      target: '#modalTitle',
      title: AccountChartModal.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{AccountChartModal.Step2.Text}</div>,
      placement: 'auto',
      target: '#accountChartForm',
      title: AccountChartModal.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{AccountChartModal.Step3.Text}</div>,
      placement: 'auto',
      target: '#isActiveToggle',
      title: AccountChartModal.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{AccountChartModal.Step4.Text}</div>,
      placement: 'auto',
      target: '#modalNavButtons',
      title: AccountChartModal.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  return {
    defaultTour,
    accountChartSettingsTour,
  };
}
