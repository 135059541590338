import React, { useCallback } from 'react';
import { ColumnInfo } from '@/Types';
import { Column, IColumnProps } from 'devextreme-react/data-grid';
import { getCalculateDisplayValue, getColumnRender, getTypeByColumnInfo } from '../services/utils';

export const useColumns = <TData extends object>(columnInfos?: ColumnInfo<TData>[]) => {
  const generateColumns = useCallback((columnInfos?: ColumnInfo<TData>[]) => {
    return columnInfos?.map(
      ({
        render,
        columnType,
        children,
        fieldName,
        headerText,
        visible,
        defaultVisible,
        alignment,
        width,
        minWidth,
        format,
        calculateCellValue,
        allowEditing = false,
      }) => {
        if (children?.length) {
          return (
            <Column key={`${fieldName as string}`} caption={headerText}>
              {generateColumns(children)}
            </Column>
          );
        } // if

        const props: IColumnProps & { key: string } = {
          allowFiltering: true,
          key: `${fieldName as string}`,
          dataField: fieldName,
          caption: headerText,
          format,
          width,
          minWidth,
          alignment,
          dataType: getTypeByColumnInfo(columnType),
          cellRender: render
            ? (params: { data: TData; rowIndex: number }) => render(params.data, params.rowIndex)
            : undefined,
          customizeText: (cellInfo: any) => {
            if (cellInfo.target == 'headerFilter' && cellInfo.groupInterval) {
              if (cellInfo.groupInterval == 'month') {
                return cellInfo.valueText;
              } else {
                return `${cellInfo.valueText} (${cellInfo.groupInterval})`;
              }
            }

            if (columnType !== undefined) {
              return getColumnRender(columnType, cellInfo.value);
            }
          },
          allowEditing,
          calculateDisplayValue: (row: TData) =>
            calculateCellValue?.(row) ?? getCalculateDisplayValue(row, columnType, fieldName),
        };

        if (defaultVisible !== undefined) {
          props.defaultVisible = defaultVisible;
        } else {
          props.visible = visible;
        }

        return <Column {...props} key={`${fieldName as string}`} />;
      },
    );
  }, []);

  return generateColumns(columnInfos);
};
