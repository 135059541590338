import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useAllocateSettings() {
  const {
    tours: {
      OnlineArchiveSettings: { Allocate },
    },
  } = useLocalization();

  const defaultTour: TourStep[] = [
    {
      content: <div>{Allocate.Step1.Text}</div>,
      placement: 'auto',
      target: '#allocateCategories',
      title: Allocate.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Allocate.Step2.Text}</div>,
      placement: 'bottom',
      target: '#categoryList',
      title: Allocate.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      styles: {
        spotlight: {
          maxWidth: '200px',
        },
      },
    },
    {
      content: <div>{Allocate.Step3.Text}</div>,
      placement: 'auto',
      target: '#categoryList',
      title: Allocate.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      styles: {
        spotlight: {
          marginLeft: '200px',
          maxWidth: '70px',
        },
      },
    },
  ];

  return {
    defaultTour,
  };
}
