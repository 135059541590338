import { useAccountSettings } from '../modules/AccountSettings/AccountSettings';
import { useAddressBookSettings } from '../modules/AddressBookSettings/AddressBookSettings';
import { useMemo } from 'react';
import { PagesRouting } from '@/Routing';
import { globalHistory } from '@/GlobalHistory';
import { useMandatSettings } from '../modules/MandatSettings/MandatSettings';
import { useSyncSettings } from '@/Components/Tours/modules/SyncSettings/SyncSettings';
import { useOrderAutomationSettings } from '@/Components/Tours/modules/OrderAutomationSettings/OrderAutomationSettings';
import { usePackageSettings } from '@/Components/Tours/modules/PackageSettings/PackageSettings';
import { useMarketplaceSettings } from '@/Components/Tours/modules/MarketplaceSettings/MarketplaceSettings';
import { useAmountAutomationSettings } from '@/Components/Tours/modules/ShippingAutomationSettings/AmountAutomationSettings';
import { useCategoryAutomationSettings } from '@/Components/Tours/modules/ShippingAutomationSettings/CategoryAutomationSettings';
import { usePackagingAutomationSettings } from '@/Components/Tours/modules/ShippingAutomationSettings/PackagingAutomationSettings';
import { useMixCategoryAutomationSettings } from '@/Components/Tours/modules/ShippingAutomationSettings/MixCategoryAutomationSettings';
import { useGeneralArchiveSettings } from '@/Components/Tours/modules/OnlineArchiveSettings/GeneralArchiveSettings';
import { useDocumentCategoriesSettings } from '@/Components/Tours/modules/OnlineArchiveSettings/DocumentCategoriesSettings';
import { useAllocateSettings } from '@/Components/Tours/modules/OnlineArchiveSettings/AllocateSettings';
import { useAccountChartSettings } from '@/Components/Tours/modules/OnlineArchiveSettings/AccountChartSettings';
import { useUploadTemplatesSettings } from '@/Components/Tours/modules/OnlineArchiveSettings/UploadTemplatesSettings';
import { useScanDocumentsSettings } from '@/Components/Tours/modules/OnlineArchiveSettings/ScanDocumentsSettings';

export function useTours(runTour: boolean, currentTour: string) {
  const route = globalHistory.location.pathname;
  const accountSettings = useAccountSettings();
  const addressBookSettings = useAddressBookSettings();
  const mandatSettings = useMandatSettings();
  const syncSettings = useSyncSettings();
  const orderAutomationSettings = useOrderAutomationSettings();
  const packageSettings = usePackageSettings();
  const marketplaceSettings = useMarketplaceSettings();

  const amountAutomationSettings = useAmountAutomationSettings();
  const categoryAutomationSettings = useCategoryAutomationSettings();
  const packagingAutomationSettings = usePackagingAutomationSettings();
  const mixCategoryAutomationSettings = useMixCategoryAutomationSettings();

  const generalArchiveSettings = useGeneralArchiveSettings();
  const documentCategoriesSettings = useDocumentCategoriesSettings();
  const allocateSettings = useAllocateSettings();
  const uploadTemplatesSettings = useUploadTemplatesSettings();
  const accountChartSettings = useAccountChartSettings();
  const scanDocumentsSettings = useScanDocumentsSettings();

  return useMemo(() => {
    const currentPageTours = {
      [PagesRouting.SettingsPages.AccountSettings]: accountSettings,
      [PagesRouting.SettingsPages.AddressBookSettings]: addressBookSettings,
      [PagesRouting.SettingsPages.MandatsSettings]: mandatSettings,
      [PagesRouting.SettingsPages.SynchronizeSettings]: syncSettings,
      [PagesRouting.SettingsPages.OrderAutomationSettings]: orderAutomationSettings,
      [PagesRouting.SettingsPages.PackagesSettings]: packageSettings,
      [PagesRouting.SettingsPages.MarketplacesSettings]: marketplaceSettings,

      [PagesRouting.SettingsPages.ShippingAutomationSettings.AmountAutomation]: amountAutomationSettings,
      [PagesRouting.SettingsPages.ShippingAutomationSettings.CategoryAutomation]: categoryAutomationSettings,
      [PagesRouting.SettingsPages.ShippingAutomationSettings.PackagingAutomation]: packagingAutomationSettings,
      [PagesRouting.SettingsPages.ShippingAutomationSettings.MixCategoryAutomation]: mixCategoryAutomationSettings,

      [PagesRouting.SettingsPages.OnlineArchiveSetting.General]: generalArchiveSettings,
      [PagesRouting.SettingsPages.OnlineArchiveSetting.DocumentCategories]: documentCategoriesSettings,
      [PagesRouting.SettingsPages.OnlineArchiveSetting.AllocateCategories]: allocateSettings,
      [PagesRouting.SettingsPages.OnlineArchiveSetting.UploadTemplates]: uploadTemplatesSettings,
      [PagesRouting.SettingsPages.OnlineArchiveSetting.AccountChart]: accountChartSettings,
      [PagesRouting.SettingsPages.OnlineArchiveSetting.ScanDocuments]: scanDocumentsSettings,
    }[route];

    if (!currentPageTours) return [];

    return currentPageTours[currentTour as keyof typeof currentPageTours];
  }, [route, runTour, currentTour]);
}
